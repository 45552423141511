<template lang="pug">
  div.wrap-sign-in
    div(id="firebaseui-auth-container")
      div.wrap-signin.line.mt12.pt10(@click="signInWithLine")
        img(src="@/assets/LINE_Brand_icon.png")
        span.bold LINEでサインイン
      //- p.divide-text SpotWorkに登録済みの方はこちらから
      //- div.wrap-signin.google.pt10(@click="signInWithGoogleAndLinkWithLine")
      //-   img(src="@/assets/google_logo.png")
      //-   span.bold Googleでログイン
      //- div.wrap-signin.email.pt10(@click="signInWithEmailAndLinkWithLine")
      //-   v-icon mdi-email
      //-   span.bold E-mailでログイン
</template>

<style lang="scss">
.wrap-sign-in {
  .firebaseui-idp-list {
    padding: 0 !important;
  }
}
</style>

<script>
import { firebase, auth, functions } from '@/components/utils/firebase'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
const ui = new firebaseui.auth.AuthUI(firebase.auth())

export default {
  props: {
    accessToken: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isSignInUILoaded: false,
      isSignUp: false
    }
  },
  watch: {
    $route (to, from) {
      this.isSignUp = (to.name === 'sign-up')
      if (this.isSignUp) {
        setTimeout(this.replaceSignInText, 10)
      }
      if (!this.isSignUp) {
        setTimeout(this.replaceSignUpText, 10)
      }
    }
  },
  created () {
    if (this.$route.name.indexOf('sign-up') !== -1) this.isSignUp = true
  },
  mounted () {
    this.$nextTick(() => {
      this.setupSignInUi()
    })
  },
  methods: {
    setupSignInUi () {
      const vInstance = this
      const uiConfig = {
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        callbacks: {
          uiShown () {
            vInstance.isSignInUILoaded = true
          },
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            this.$emit('signInSuccess', redirectUrl)
            return false
          }
        },
        // signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        // {
        //   provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
        // },
        // {
        //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        //   requireDisplayName: true
        // },
        // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
        // ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: 'https://truffle.ai/terms.pdf',
        // Privacy policy url/callback.
        privacyPolicyUrl: function () {
          // window.location.assign("<your-privacy-policy-url>")
          window.location.href = 'https://truffle.ai/privacy.pdf'
        }
      }

      // Initialize the FirebaseUI Widget using Firebase.

      // The start method will wait until the DOM is loaded.

      // this.$nextTick(() => {
      //   ui.start("#firebaseui-auth-container", uiConfig)
      // })

      setTimeout(() => {
        ui.start('#firebaseui-auth-containerd', uiConfig)
        // if (this.isSignUp) setTimeout(this.replaceSignInText, 10)
      }, 800)
    },
    async signInWithLine () {
      this.$emit('showNowLoading')
      const login = functions.httpsCallable('loginWithLine')
      if (this.accessToken) {
        const token = this.accessToken
        const result = await login({ token })
        if (result.data.error) {
          console.error('login error', result.data.error)
        } else {
          const res = await auth.signInWithCustomToken(result.data.token)
          this.uid = res.user.uid
          this.$emit('signInSuccess')
        }
      } else {
        console.log('token is not exist')
        window.alert('ログインエラーが発生しました。もう一度やり直してください')
      }
    }
    // async signInWithGoogleAndLinkWithLine () {
    //   const googleProvider = new firebase.auth.GoogleAuthProvider()
    //   firebase.auth().signInWithRedirect(googleProvider).then((result) => {
    //     this.uid = result.user.uid
    //   }).catch((error) => {
    //     const errorMessage = error.message
    //     console.error(errorMessage)
    //   })
    // },
    // async signInWithEmailAndLinkWithLine () {
    //   const emailProvider = new firebase.auth.EmailAuthProvider()
    //   firebase.auth().signInWithRedirect(emailProvider).then((result) => {
    //     this.uid = result.user.uid
    //   }).catch((error) => {
    //     const errorMessage = error.message
    //     console.error(errorMessage)
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.wrap-signin {
  position: relative;
  width: 220px;
  height: 40px;
  bottom: 16px;
  left: 50%;
  margin-left: -110px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  text-decoration: none;
  cursor: pointer;
  span {
    position: relative;
    font-size: 14px;
  }
  img {
    position: relative;
  }
}

.divide-text {
  font-size: 14px;
  font-weight: bold;
  color: $text_secondary;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.line {
  background: #06C755;
  span {
    color: #fff;
    top: -10px;
  }
  img {
    width: 26px;
    margin-left: 11px;
    margin-right: 10px;
    top: -2px;
  }
}

.google {
  background: #fff;
  margin-bottom: 16px;
  span {
    color: #757575;
    top: -3px;
  }
  img {
    width: 18px;
    margin-left: 14px;
    margin-right: 12px;
  }
}

.email {
  background: #fff;
  span {
    color: #757575;
    top: -2px;
  }
  i {
    font-size: 20px;
    width: 22px;
    margin-left: 14px;
    margin-right: 12px;
    top: -3px;
  }
}

/* Collapse height of firebase ui loading spinner container */
.mdl-card .firebaseui-callback-indicator-container {
  height: 2px;
}

/* Collapse height of firebase ui loading spinner container */
.mdl-card.firebaseui-container {
  min-height: 2px;
}
</style>
