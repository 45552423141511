<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="loginFailed")
    NowLoading(:show="showNowLoading") {{ loadingText }}
    div.wrap-sign-ui.f.fh
      div.sign-ui
        div.wrap-title.f.fc.mb18
          img(src="/img/logo.png").logo-img
        span.welcome.block.text-center.fz14.bold.mb40 Come-On! へようこそ！
        div.wrap-sign-in-ui.mb24
          SignIn(:accessToken="accessToken" @signInSuccess="onLoggedIn" @showNowLoading="loginWithLine")
        //- div.wrap-select-sign-in-up.f.fc
        //-   span(v-if="$route.name === 'sign-up'" @click="$router.push('/sign-in')").fz12 サインイン
        //-   span(v-if="$route.name === 'sign-in'" @click="$router.push('/sign-up')").fz12 サインアップ
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-sign-ui {
  width: 100%;
  height: 100vh;
  .sign-ui {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .logo-img {
      width: 120px;
    }
    .wrap-select-sign-in-up {
      color: $active_color;
    }
  }
}
</style>

<script>
import { firebase } from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/shared/Auth'
import SignIn from '@/components/sign-in/firebase-sign-in-ui'
import NowLoading from '@/components/shared/NowLoading'
import { database } from '../../functions/shared/database'
import liff from '@line/liff'
import { liffId } from '@/components//utils/config'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    SignIn,
    NowLoading
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    resultRedirect: null,
    redirectUrl: null,
    showNowLoading: true,
    accessToken: '',
    lineProfile: {},
    loadingText: ''
  }),
  computed: {
    ...mapStateAuth(['isLoggedIn', 'uid'])
  },
  async created () {
    await liff.init({ liffId: liffId })
      .catch(err => {
        console.error('LIFFの初期化に失敗\n' + err)
      })
    if (!liff.isLoggedIn()) {
      liff.login()
    }
    this.accessToken = liff.getAccessToken()
  },
  async mounted () {
    if (this.uid) {
      this.$router.push('/create-order')
      this.showNowLoading = false
    }
  },
  methods: {
    async loginFailed () {
      this.accessToken = liff.getAccessToken()
      this.showNowLoading = false
    },
    async onLoggedIn () {
      this.showNowLoading = false

      await this.sleepByPromise(0.8)
      const firebaseUser = firebase.auth().currentUser
      this.lineProfile = await liff.getProfile()

      if (firebaseUser) {
        this.uid = firebaseUser.uid
      }

      if (this.uid) {
        this.showNowLoading = true

        const user = await database.userCollection().findById(this.uid)

        if (!user) {
          const defaultUserIcon =
            'https://firebasestorage.googleapis.com/v0/b/fir-tmp-project.appspot.com/o/public%2Faccount.png?alt=media&token=4ad2981f-61ac-42d9-a5ed-45eda74077d0'

          const userObj = {
            uid: this.uid,
            lineId: this.lineProfile.userId,
            name: (firebaseUser.displayName ? firebaseUser.displayName : this.lineProfile.displayName),
            profile: 'No Profile',
            iconURL: (firebaseUser.photoURL ? firebaseUser.photoURL : defaultUserIcon),
            type: ['user'],
            lastSignInTime: (firebaseUser.metadata.lastSignInTime ? firebaseUser.metadata.lastSignInTime : new Date()),
            createdAt: (firebaseUser.metadata.creationTime ? firebaseUser.metadata.creationTime : new Date())
          }

          await database.userCollection().set(this.uid, userObj)
        } else {
          if (!user.lineId) {
            try {
              await database.userCollection().update(this.uid, { lineId: this.lineProfile.userId })
            } catch (e) {
              console.error(JSON.stringify(e, null, '  '))
              return { error: e.message }
            }
          }
          if (!user.lineName || user.lineName !== this.lineProfile.displayName) {
            try {
              await database.userCollection().update(this.uid, { lineName: this.lineProfile.displayName })
            } catch (e) {
              console.error(JSON.stringify(e, null, '  '))
              return { error: e.message }
            }
          }

          const addresses = await database.addressCollection(this.uid).all()
          if (addresses.length > 0) {
            this.$router.push('/create-order')
            this.showNowLoading = false
            return
          }
        }

        this.showNowLoading = false
        this.$router.push('/tutorial')
      }
    },
    loginWithLine () {
      this.loadingText = '現在サインイン中です...'
      this.showNowLoading = true
    },
    signInSuccess (redirectUrl) {
      this.redirectUrl = redirectUrl
    },
    sleepByPromise (sec) {
      return new Promise(resolve => setTimeout(resolve, sec * 1000))
    }
  }
}
</script>
